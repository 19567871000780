exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-case-studies-tsx": () => import("./../../../src/pages/case-studies.tsx" /* webpackChunkName: "component---src-pages-case-studies-tsx" */),
  "component---src-pages-clinic-finder-tsx": () => import("./../../../src/pages/clinic-finder.tsx" /* webpackChunkName: "component---src-pages-clinic-finder-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-events-conferences-tsx": () => import("./../../../src/pages/events/conferences.tsx" /* webpackChunkName: "component---src-pages-events-conferences-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-events-webinars-tsx": () => import("./../../../src/pages/events/webinars.tsx" /* webpackChunkName: "component---src-pages-events-webinars-tsx" */),
  "component---src-pages-events-workshops-tsx": () => import("./../../../src/pages/events/workshops.tsx" /* webpackChunkName: "component---src-pages-events-workshops-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legals-tsx": () => import("./../../../src/pages/legals.tsx" /* webpackChunkName: "component---src-pages-legals-tsx" */),
  "component---src-pages-our-specialists-tsx": () => import("./../../../src/pages/our-specialists.tsx" /* webpackChunkName: "component---src-pages-our-specialists-tsx" */),
  "component---src-pages-press-media-tsx": () => import("./../../../src/pages/press-media.tsx" /* webpackChunkName: "component---src-pages-press-media-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */),
  "component---src-pages-testimonials-tsx": () => import("./../../../src/pages/testimonials.tsx" /* webpackChunkName: "component---src-pages-testimonials-tsx" */),
  "component---src-pages-workstation-tsx": () => import("./../../../src/pages/workstation.tsx" /* webpackChunkName: "component---src-pages-workstation-tsx" */),
  "component---src-templates-addon-tsx": () => import("./../../../src/templates/addon.tsx" /* webpackChunkName: "component---src-templates-addon-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-treatment-tsx": () => import("./../../../src/templates/treatment.tsx" /* webpackChunkName: "component---src-templates-treatment-tsx" */)
}

